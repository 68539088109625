/* Container that shows the pricing summary */
.summaryContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
        "summary";
}

.summaryContent {
    width: 100%;
    grid-area: summary;
}

.summaryLoadingOverlay {
    width: 100%;
    grid-area: summary;
    background-color: #AAA;
    z-index: 2000;
    opacity: 0.3;
}
