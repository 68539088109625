//
//  piosolver.scss
//
//  PioSOLVER styles

// Fonts: Inter + Murecho
// https://rsms.me/inter/

@import url("https://rsms.me/inter/inter.css");

// Default variable overrides
// Colors same as web/src/css/custom.css
$body-bg: #fefbf7;
$body-color: #222222;
$tray-color: #f0ede9;

$btn-border-width: 0.25px;
$btn-close-color: $body-color;

$blue: #1f78b4;
// $indigo:  #6610f2;
// $purple:  #6f42c1;
// $pink:    #d63384;
// $red:     #dc3545;
$orange: #d95f02;
$yellow: #dead3b;
$green: #4a9c79;
// $teal:    #20c997;
// $cyan:    #0dcaf0;

$secondary: #636363;
$light: hsl(140, 10%, 94%);

$font-family-sans-serif: "Inter", -apple-system, system-ui, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$breadcrumb-divider: quote(">");
$breadcrumb-active-color: $body-color;
// $breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='white'/%3E%3C/svg%3E");
$form-select-indicator-color: $body-color;
$form-check-input-border: 1px solid rgba($body-color, 0.9);

$modal-content-bg: rgb(52, 57, 62);

$text-muted: rgba(255, 255, 255, 0.55);
$tooltip-max-width: 400px;

@import "~bootstrap/scss/bootstrap.scss";

.breadcrumb {
  margin-bottom: 0px !important;
  font-size: 0.85rem;
  .breadcrumb-item {
    span {
      color: $gray-600;
    }
    button {
      font-size: 0.85rem;
    }
  }
}

@include media-breakpoint-up(sm) {
  .breadcrumb {
    font-size: unset;
    .breadcrumb-item {
      button {
        font-size: unset;
      }
    }
  }
}

h1 {
  @extend .pb-4;
}

h2 {
  @extend .py-2;
}

h3,
h4 {
  @extend .py-1;
}

.no-wrap {
  white-space: nowrap;
}

.text-white {
  text-decoration-color: $body-color;
}

.text-assistance {
  color: $gray-500;
  font-style: italic;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clickable {
  cursor: pointer;
}

.no-decor {
  text-decoration: none !important;
}

.order-summary {
  @extend .p-4, .mb-3;
  background-color: $tray-color;
  --bs-bg-opacity: 0.1;
}

.table {
  --bs-table-bg: $tray-color;
}
